import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { lazy, Suspense } from "react";
import useLazyGoogleTag from "./app/hooks/useLazyGoogleTag";

// import { IntercomProvider } from "react-use-intercom";
import PresaleGuide from "./app/pages/PresaleGuide";

// const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;

const FacebookPixel = lazy(() => import("./FacebookPixel"));

const Sentry = lazy(() => import("./Sentry"));

const Plans = lazy(() => import("./app/pages/Plans"));

const Packs = lazy(() => import("./app/pages/Packs"));

const PaymentSuccess = lazy(() => import("./app/pages/PaymentSuccess"));

const ChatBot = lazy(() => import("./app/pages/ChatBot"));

const Questionnaire = lazy(() => import("./app/pages/Questionnaire"));

// const Fullstory = lazy(
//   () => import(/* webpackChunkName: "Fullstory" */ "./Fullstory")
// );

function App() {
  useLazyGoogleTag();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <>Root -2</>,
    },
    {
      path: "/plans",
      element: (
        <Suspense fallback={<></>}>
          {/* <IntercomProvider appId={INTERCOM_APP_ID as string}> */}
          <Plans />
          {/* </IntercomProvider> */}
        </Suspense>
      ),
    },
    {
      path: "/payment-success",
      element: (
        <Suspense fallback={<></>}>
          <PaymentSuccess />
        </Suspense>
      ),
    },
    {
      path: "/packs",
      element: (
        <Suspense fallback={<></>}>
          <Packs />
        </Suspense>
      ),
    },
    {
      path: "/chat-bot",
      element: (
        <Suspense fallback={<></>}>
          <ChatBot />
        </Suspense>
      ),
    },
    {
      path: "/chat-bot/:bot_id",
      element: (
        <Suspense fallback={<></>}>
          <ChatBot />
        </Suspense>
      ),
    },
    {
      path: "/questionnaire",
      element: (
        <Suspense fallback={<></>}>
          <Questionnaire />
        </Suspense>
      ),
    },
    {
      path: "/presale-guide",
      element: (
        <Suspense fallback={<></>}>
          <PresaleGuide />
        </Suspense>
      ),
    },
  ]);

  return (
    <>
      {window.location.pathname !== "/chat-bot" && (
        <>
          <Sentry />
          <FacebookPixel />
        </>
      )}

      <RouterProvider router={router} />
      {/* <Fullstory /> */}
    </>
  );
}

export default App;
